import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { fontSizes } from '../helpers/variables';
import {
    gapable,
    textAlignable,
    textColorable,
    fontSizable,
    fontChangeable,
    displayable,
} from '../helpers/traits';

/**
 * Erzeugt eine Headline
 * @param {string} props.className Optional: zusätzliche CSS-Klassen
 * @param {string} props.level Die Ebene der Überschrift ('h1', 'h2', 'h3', 'h4', 'h5', 'h6')
 * @param {string} props.gap Optional: Der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl') - Standard: 'l'
 * @param {string} props.textAlign Optional: Ausrichtung des Textes
 *
 * @example <Headline level="h2" gap="m" textAlign="center">Lorem ipsum Headline</Headline>
 */
export const Headline = styled(({ level, children, className }) => {
    const HL = level;
    return <HL className={className}>{children}</HL>;
})`
    ${({ level }) => fontSizes[level]};
    ${({ level }) => (level === 'h1' || level === 'h2') && 'text-transform: uppercase'};
    font-weight: 700;
    line-height: 1.35;
    margin-top: 0;
    ${gapable('m')};
    ${textAlignable()};
    ${textColorable('primary')};
`;

Headline.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']).isRequired,
    gap: gapable.propType,
    textAlign: textAlignable.propType,
};

Headline.defaultProps = {
    className: null,
    gap: null,
    textAlign: null,
};

/**
 * Erzeugt einen Absatz
 * @param {bool} props.bold Optional: Zeichnet den Text als "fett" aus
 * @param {string} props.fontSize Optional: Die Schriftgröße ('s', 'm', 'l' ...)
 * @param {string} props.gap Optional: Der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl') - Standard: 'm'
 * @param {string} props.textAlign Optional: Ausrichtung des Textes
 *
 * @example <P bold fontSize="l" gap="l" textAlign="center">...</P>
 */
export const P = styled.p`
    font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
    line-height: 1.6;
    margin: 0.5em 0 1em;
    ${fontSizable('s')};
    ${textAlignable()};
    ${({ gap }) => gap && gapable()};

    &:last-child {
        margin-bottom: 0;
    }
`;

P.propTypes = {
    bold: PropTypes.bool,
    children: PropTypes.node.isRequired,
    fontSize: fontSizable.propType,
    gap: gapable.propType,
    textAlign: textAlignable.propType,
};

P.defaultProps = {
    bold: false,
    fontSize: null,
    gap: null,
    textAlign: null,
};

/**
 * Erzeugt einen hervorgehobenen Text
 * @param {bool} props.bold Optional: Zeichnet den Text als "fett" aus
 * @param {string} props.font Optional: Die Schriftart
 * @param {string} props.fontSize Optional: Die Schirftröße
 * @param {string} props.gap Optional: Der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl') - Standard: 'm'
 * @param {string} props.textAlign Optional: Ausrichtung des Textes
 * @param {string} props.textColor Optional: Farbe des Textes
 *
 * @example <Highlight bold fontSize="l" gap="l" textAlign="center">...</Highlight>
 */
export const Highlight = styled.strong`
    line-height: 1.35;
    font-weight: 700;
    ${textColorable('primary')};
    ${displayable('inline-block')};
    ${fontSizable('m')};
    ${gapable('none')};
    ${textAlignable()};
    ${fontChangeable()};
`;

Highlight.propTypes = {
    bold: PropTypes.bool,
    children: PropTypes.node.isRequired,
    display: displayable.propType,
    font: fontChangeable.propType,
    fontSize: fontSizable.propType,
    gap: gapable.propType,
    textAlign: textAlignable.propType,
    textColor: textColorable.propType,
};

Highlight.defaultProps = {
    bold: false,
    display: null,
    font: null,
    fontSize: null,
    gap: null,
    textAlign: null,
    textColor: null,
};
