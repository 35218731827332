import React from 'react';
import Layout from '../components/Layout';
import Section from '../components/Section';
import Button from '../components/Button';
import { Headline } from '../components/Typography';

const ImprintPage = () => (
    <Layout title="Impressum" description="Schütz Dienstleistungen">
        <Section bg="gray" container="l">
            <Headline level="h2">404 - Die angefragte Seite wurde nicht gefunden</Headline>
            <Button to="/">Zur Startseite</Button>
        </Section>
    </Layout>
);

export default ImprintPage;
